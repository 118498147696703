/** @jsx h */

import type { VNode } from 'preact';

import { clsx } from 'clsx';
import { h } from 'preact';
import { useEffect, useState } from 'preact/hooks';

import type { TeaserTextRenderFunction, TeaserTextRenderProps } from '../../../types';

import { CLOSE_BUTTON_POSITION, Z_INDEX_MAX } from '../../../constants';
import { MODAL_TYPE, removeLastClosedTime, track } from '../../../lib';
import { CLOSE_BUTTON_SIZE } from '../../../types';
import { useConsentPopupContext } from '../context';

import { CloseButton } from './close-button';

type ConsentPopupTeaserProps = {
    className ?: string,
    customText ?: string | TeaserTextRenderFunction,
    closeButtonPosition ?: CLOSE_BUTTON_POSITION,
};

export const ConsentPopupTeaser = ({
    className,
    customText,
    closeButtonPosition = CLOSE_BUTTON_POSITION.RIGHT
} : ConsentPopupTeaserProps) : VNode | null => {
    const {
        popupID,
        openPopup,
        isTeaserOpen,
        accountToken,
        brandConfig,
        suppressTeaser,
        properties
    } = useConsentPopupContext();

    const [ hover, setHover ] = useState(false);

    useEffect(() => {
        track('consent_teaser_render', { accountToken });
    }, []);

    useEffect(() => {
        track('consent_teaser_init', {
            accountToken
        });

        if (suppressTeaser.suppressed) {
            track('consent_teaser_suppress', {
                reason: suppressTeaser.reason
            });
        } else {
            track('consent_teaser_render', {
                accountToken
            });
        }
    }, [ suppressTeaser.suppressed ]);

    if (!isTeaserOpen || suppressTeaser.suppressed) {
        return null;
    }

    const handleClick = () : void => {
        removeLastClosedTime({ id: popupID, type: MODAL_TYPE.POPUP });
        openPopup();
    };

    const fontFamily = brandConfig?.font?.family?.primary ?? 'Helvetica Neue';
    const textColor = hover
        ? brandConfig?.teaser?.colors?.text.hover ?? brandConfig?.colors?.text ?? '#000000'
        : brandConfig?.teaser?.colors?.text.base ?? brandConfig?.colors?.text ?? '#000000';
    const backgroundColor = hover
        ? brandConfig?.teaser?.colors?.background.hover ?? brandConfig?.colors?.background ?? '#ffffff'
        : brandConfig?.teaser?.colors?.background.base ?? brandConfig?.colors?.background ?? '#ffffff';
    const teaserStyle = brandConfig?.teaser?.style ?? {};

    const renderProps : TeaserTextRenderProps = {
        properties
    };

    const teaserText = typeof customText === 'function'
        ? customText(renderProps)
        : customText ?? 'View offer';

    const content = [
        <div
            className={ 'relative w-full h-full flex items-center justify-center z-10' }>
            { teaserText }
        </div>,
        <CloseButton
            key={ 'close' }
            className={ 'relative top-auto right-auto' }
            size={ CLOSE_BUTTON_SIZE.SM }
            animate={ false }
            type={ MODAL_TYPE.TEASER }
        />
    ];

    if (closeButtonPosition === CLOSE_BUTTON_POSITION.LEFT) {
        content.reverse();
    }

    return (
        <button
            type={ 'button' }
            onClick={ handleClick }
            onMouseEnter={ () => setHover(true) }
            onMouseLeave={ () => setHover(false) }
            className={
                clsx(
                    'bottom-5 left-5 fixed w-fit',
                    'flex items-center',
                    'py-2.5 px-3 md:px-3.5 space-x-2.5 md:space-x-3',
                    'rounded-[3px] md:rounded-[4px]',
                    'text-slate-900',
                    'fade-in-up',
                    'cursor-pointer',
                    'focus:outline-none',
                    'text-md md:text-lg',
                    className
                )
            }
            style={
                {
                    fontFamily: `'${ fontFamily }', sans-serif`,
                    color:      textColor,
                    backgroundColor,
                    zIndex:     Z_INDEX_MAX,
                    ...teaserStyle
                }
            }
            aria-label={ 'View offer' }
        >
            { content }
        </button>
    );
};
