/** @jsx h */
import type { ComponentChildren, VNode } from 'preact';

import { h } from 'preact';
import { useEffect, useMemo, useState } from 'preact/hooks';
import { twMerge as tw } from 'tailwind-merge';

import type { PageChildrenRenderFunction, PageChildrenRenderProps } from '../../../types';

import { ConsentPopupPageContext, useConsentPopupContext } from '../context';

import { ConsentPopupForm } from './form';

import type { COUNTRY, Milliseconds } from '@onetext/api';

type ConsentPopupPageProps = {
    children : ComponentChildren | PageChildrenRenderFunction,
    className ?: string,
    openKeyboardOnSubmit ?: boolean,
    submitDelay ?: Milliseconds,
    background ?: JSX.Element,
    onSubmitStart ?: () => Promise<void> | void,
    shownToCountries ?: Array<COUNTRY>,
};

export const ConsentPopupPage = ({
    children,
    className = undefined,
    openKeyboardOnSubmit = false,
    submitDelay,
    onSubmitStart,
    shownToCountries,
    background
} : ConsentPopupPageProps) : VNode | null => {
    const {
        activePageID,
        registerPage,
        submitPage,
        getNewPageID,
        config,
        properties
    } = useConsentPopupContext();

    const [ pageID ] = useState(getNewPageID);

    useEffect(() => {
        const shouldRegister = !shownToCountries || (
            config.customerCountry &&
            shownToCountries.includes(config.customerCountry)
        );

        if (shouldRegister) {
            return registerPage(pageID);
        }
    }, [ pageID, registerPage, shownToCountries, config.customerCountry ]);

    if (activePageID !== pageID) {
        return null;
    }

    const pageContext = useMemo(() => {
        return {
            openKeyboardOnSubmit
        };
    }, [ openKeyboardOnSubmit ]);

    const renderProps : PageChildrenRenderProps = {
        properties,
        customerCountry: config.customerCountry
    };

    const renderChildren = typeof children === 'function'
        ? (children as PageChildrenRenderFunction)(renderProps)
        : children;

    return (
        <ConsentPopupPageContext.Provider value={ pageContext }>
            <ConsentPopupForm
                onSubmitStart={ onSubmitStart }
                onSubmit={ submitPage }
                submitDelay={ submitDelay }
                openKeyboardOnSubmit={ openKeyboardOnSubmit }
            >
                { background }
                <div className={ tw('relative w-full h-full pb-32 md:pb-16', className) }>
                    { renderChildren }
                </div>
            </ConsentPopupForm>
        </ConsentPopupPageContext.Provider>
    );
};
