import type { PopupID } from '../types';

import { getStoredAccountToken } from '../biz';

import { isCoolingDown, MODAL_TYPE } from './cooldown';
import { isDevelopment, isPreviewMode } from './sdk';

enum SUPPRESS_REASON {
    SUPPRESSED_ROUTE = 'suppressed_route',
    COOLING_DOWN = 'cooling_down',
    CUSTOMER_RECOGNIZED = 'customer_recognized'
}

type ShouldSuppressOptions = {
    id : PopupID,
    suppressedRoutes ?: ReadonlyArray<string>,
    suppressedKeywords ?: ReadonlyArray<string>,
    suppressedQueryParams ?: ReadonlyArray<string>,
    type : MODAL_TYPE,
};

export type ShouldSuppressResult =
    | {
        suppressed : true,
        reason : SUPPRESS_REASON,
    }
    | {
        suppressed : false,
    };

const shouldSuppress = ({
    id,
    suppressedRoutes = [],
    suppressedKeywords = [],
    suppressedQueryParams = [],
    type
} : ShouldSuppressOptions) : ShouldSuppressResult => {
    if (isDevelopment() || isPreviewMode()) {
        return { suppressed: false };
    }

    const currentURL = window.location.href;
    const currentPath = window.location.pathname;
    const currentSearch = window.location.search;
    const searchParams = new URLSearchParams(currentSearch);

    const isSuppressedRouteResult = {
        suppressed: true,
        reason:     SUPPRESS_REASON.SUPPRESSED_ROUTE
    };

    if (suppressedRoutes.some((route : string) => currentPath.startsWith(route))) {
        return isSuppressedRouteResult;
    }

    if (suppressedKeywords.some((keyword : string) => currentURL.includes(keyword))) {
        return isSuppressedRouteResult;
    }

    if (suppressedQueryParams.some((param : string) => {
        if (param.includes('=')) {
            const [ key, expectedValue ] = param.split('=');
            const actualValue = searchParams.get(key ?? '');
            return actualValue === expectedValue;
        }

        return searchParams.has(param);
    })) {
        return isSuppressedRouteResult;
    }

    if (getStoredAccountToken()) {
        return {
            suppressed: true,
            reason:     SUPPRESS_REASON.CUSTOMER_RECOGNIZED
        };
    }

    if (isCoolingDown({ id, type })) {
        return {
            suppressed: true,
            reason:     SUPPRESS_REASON.COOLING_DOWN
        };
    }

    return { suppressed: false };
};

export const shouldSuppressTeaser = (options : Omit<ShouldSuppressOptions, 'type'>) : ShouldSuppressResult =>
    shouldSuppress({ ...options, type: MODAL_TYPE.TEASER });

export const shouldSuppressPopup = (options : Omit<ShouldSuppressOptions, 'type'>) : ShouldSuppressResult =>
    shouldSuppress({ ...options, type: MODAL_TYPE.POPUP });
