import type { HTTPHeaders } from '../types';

import { APIResponseError, delay, getAPIDomain, getSDKAuthHeader, promiseTry, UnhandledError } from '../lib';

import type { HTTPUrl, Milliseconds } from '@onetext/api';
import { HTTP_CONTENT_TYPE, HTTP_HEADER, HTTP_METHOD } from '@onetext/api';

const RETRY_DELAY = 1000 as Milliseconds;

const apiFetch = window.fetch;

type RequestBody = {
    [ key : string ] : unknown,
};

type CallAPIOptions = {
    method : HTTP_METHOD,
    url : string,
    body ?: RequestBody,
    headers ?: HTTPHeaders,
    allowedRetriesOnNetworkError ?: number,
    allowedRetriesOnServerError ?: number,
    allowedRetriesOnResponseBodyReadError ?: number,
};

export type CallAPIResult<ResponseBodyType> = {
    status : number,
    body : ResponseBodyType,
};

export const callAPI = async <ResponseBodyType>(
    opts : CallAPIOptions
) : Promise<CallAPIResult<ResponseBodyType>> => {
    const {
        method = HTTP_METHOD.POST,
        url,
        body,
        headers,
        allowedRetriesOnNetworkError = 3,
        // Pass idempotency id before increasing
        allowedRetriesOnServerError = 0,
        allowedRetriesOnResponseBodyReadError = 0
    } = opts;

    const finalHeaders : HTTPHeaders = {
        ...headers,
        [ HTTP_HEADER.CONTENT_TYPE ]: HTTP_CONTENT_TYPE.JSON,
        [ HTTP_HEADER.ACCEPT ]:       HTTP_CONTENT_TYPE.JSON
    };

    let res;

    try {
        res = await apiFetch(url, {
            method,
            headers: finalHeaders,
            body:    JSON.stringify(body)
        });
    } catch (err) {
        if (allowedRetriesOnNetworkError > 0) {
            await delay(RETRY_DELAY);

            return await callAPI({
                ...opts,
                allowedRetriesOnNetworkError: allowedRetriesOnNetworkError - 1
            });
        }

        throw new UnhandledError({
            message:       `SDK API call to ${ method } ${ url } failed`,
            originalError: err
        });
    }

    const contextID = res.headers.get(HTTP_HEADER.ONETEXT_CONTEXT_ID);
    const responseStatus = res.status;
    const isServerError = responseStatus.toString().charAt(0) === '5';

    const responseHeaders : HTTPHeaders = {};

    for (const [ key, value ] of res.headers.entries()) {
        if (key && value) {
            responseHeaders[key.toLowerCase()] = value;
        }
    }

    const allowRetry = responseHeaders[HTTP_HEADER.ALLOW_RETRY] !== 'false';

    if (
        isServerError &&
        allowRetry &&
        allowedRetriesOnServerError > 0
    ) {
        await delay(RETRY_DELAY);

        return await callAPI({
            ...opts,
            allowedRetriesOnServerError: allowedRetriesOnServerError - 1
        });
    }

    if (!res.ok) {
        throw new APIResponseError({
            message: `SDK API call to ${ method } ${ url } failed with status ${ res.status }${ contextID
                ? ` (context id: ${ contextID })`
                : '' }`,
            status:    responseStatus,
            headers:   responseHeaders,
            body:      undefined,
            hardError: isServerError
        });
    }

    let json;

    try {
        json = await res.json();
    } catch (err) {
        if (allowedRetriesOnResponseBodyReadError > 0) {
            await delay(RETRY_DELAY);

            return await callAPI({
                ...opts,
                allowedRetriesOnResponseBodyReadError: allowedRetriesOnResponseBodyReadError - 1
            });
        }

        throw new APIResponseError({
            message: `SDK API call to ${ method } ${ url } response read failed with error ${ contextID
                ? `(context id: ${ contextID })`
                : '' }`,
            originalError: err,
            status:        responseStatus,
            headers:       responseHeaders,
            body:          undefined,
            hardError:     true
        });
    }

    return {
        status: responseStatus,
        body:   json as ResponseBodyType
    };
};

type CallOneTextAPIOptions = {
    method : HTTP_METHOD,
    path : string,
    body ?: RequestBody,
    headers ?: HTTPHeaders,
    authHeaderRequired ?: boolean,
    allowedRetriesOnNetworkError ?: number,
    allowedRetriesOnServerError ?: number,
    allowedRetriesOnResponseBodyReadError ?: number,
};

export const callOneTextAPI = <ResponseBodyType>({
    method,
    path,
    body,
    headers,
    allowedRetriesOnNetworkError,
    allowedRetriesOnServerError,
    allowedRetriesOnResponseBodyReadError,
    authHeaderRequired = true
} : CallOneTextAPIOptions) : Promise<CallAPIResult<ResponseBodyType>> => {
    const url = `${ getAPIDomain() }/api/${ path }`;

    const authHeader = getSDKAuthHeader();

    if (authHeaderRequired && !authHeader) {
        throw new Error(`Can not determine SDK auth header`);
    }

    const finalHeaders = authHeader
        ? {
            ...headers,
            [HTTP_HEADER.AUTHORIZATION]: authHeader
        }
        : headers;

    let finalBody;

    if (method !== HTTP_METHOD.GET && authHeader) {
        finalBody = {
            ...body,
            [HTTP_HEADER.AUTHORIZATION]: authHeader
        };
    } else if (method === HTTP_METHOD.GET) {
        finalBody = undefined;
    } else {
        finalBody = body;
    }

    return callAPI({
        method,
        url,
        headers: finalHeaders,
        body:    finalBody,
        allowedRetriesOnNetworkError,
        allowedRetriesOnServerError,
        allowedRetriesOnResponseBodyReadError
    });
};

type ConstructOneTextURLOptions = {
    path : string,
    query ?: Record<string, string>,
};

export const constructOneTextURL = ({
    path,
    query
} : ConstructOneTextURLOptions) : HTTPUrl => {
    const queryString = new URLSearchParams(query).toString();
    return `${ getAPIDomain() }/${ path }?${ queryString }` as HTTPUrl;
};

type RedirectInPopupOptions = {
    url : string,
};

export const redirectInPopup = ({
    url
} : RedirectInPopupOptions) : Promise<void> => {
    return promiseTry(() => {
        window.open(url, '_blank');
    });
};
