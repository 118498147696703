/** @jsx h */

import type { VNode } from 'preact';

import { h } from 'preact';

import type { PopupID } from '../../../types';

import { DEFAULT_POPUP_ID } from '../../../constants';
import { getBrandSDKPopupID } from '../../../lib';

type PopupComponentProps = {
    id : string,
    suppressedRoutes ?: ReadonlyArray<string>,
};

type PopupComponent<ChildType extends JSX.Element | VNode> = (
    props : PopupComponentProps
) => ChildType | null;

export type ConsentMultiPopupThemes<ChildType extends JSX.Element | VNode> = {
    [ DEFAULT_POPUP_ID ] : PopupComponent<ChildType>,
    [ key : PopupID ] : PopupComponent<ChildType>,
};

type ConsentMultiPopupProps<ChildType extends JSX.Element | VNode> = {
    script : HTMLScriptElement,
    themes : ConsentMultiPopupThemes<ChildType>,
};

export const ConsentMultiPopup = <ChildType extends JSX.Element | VNode>({
    script,
    themes
} : ConsentMultiPopupProps<ChildType>) : VNode => {
    const currentPopupID = getBrandSDKPopupID(script);
    const finalPopupID = currentPopupID && currentPopupID in themes
        ? currentPopupID
        : DEFAULT_POPUP_ID;

    const PopupComponent = themes[finalPopupID] as PopupComponent<VNode> | undefined;

    if (!PopupComponent) {
        throw new Error(`Can not find popup component for popup ID: ${ finalPopupID }`);
    }

    return (
        <PopupComponent id={ finalPopupID } />
    );
};
