import type { JSX } from 'preact';

export type PopupID = string;

export type BrandFont = {
    family ?: {
        primary ?: string,
        secondary ?: string,
    },
};

export enum CLOSE_BUTTON_SIZE {
    XS = 'xs',
    SM = 'sm',
    MD = 'md',
    LG = 'lg'
}

export enum BUTTON_SIZE {
    XS = 'xs',
    SM = 'sm',
    MD = 'md',
    LG = 'lg',
    XL = 'xl'
}

export enum BUTTON_LEVEL {
    PRIMARY = 'primary',
    SECONDARY = 'secondary',
    TERTIARY = 'tertiary'
}

export enum BUTTON_ATTRIBUTE {
    TEXT = 'text',
    BACKGROUND = 'background',
    BORDER = 'border'
}

export enum BUTTON_STATE {
    BASE = 'base',
    HOVER = 'hover'
}

export type ButtonColors = {
    [level in BUTTON_LEVEL] ?: {
        [attribute in BUTTON_ATTRIBUTE] ?: {
            [state in BUTTON_STATE] ?: string
        }
    }
};

export type FieldStyle = JSX.CSSProperties;
export type ButtonStyle = JSX.CSSProperties;
export type TeaserStyle = JSX.CSSProperties;

export type BrandColors = {
    text ?: string,
    background ?: string,
};

export type TeaserColors = {
    text : {
        base : string,
        hover : string,
    },
    background : {
        base : string,
        hover : string,
    },
};

export type BrandConfig = {
    font ?: BrandFont,
    colors ?: BrandColors,
    field ?: {
        style ?: FieldStyle,
    },
    button ?: {
        style ?: ButtonStyle,
        colors ?: ButtonColors,
    },
    teaser ?: {
        style ?: TeaserStyle,
        colors ?: TeaserColors,
    },
};

export type SpinnerColors = {
    arrow : {
        background : string,
    },
    hubcap : {
        background : string,
        border : string,
    },
    wheel : {
        background : string,
        border : string,
    },
    freeGift : {
        background : string,
        text : string,
    },
    discount : {
        background : string,
        text : string,
    },
};
