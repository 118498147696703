import { callOneTextAPI } from './api';

import type { GetPopupConfigAPI, PopupConfig } from '@onetext/api';
import { HTTP_METHOD } from '@onetext/api';

type PopupConfigResponse = GetPopupConfigAPI['response'];

export const getPopupConfig = async () : Promise<PopupConfig> => {
    const {
        body
    } = await callOneTextAPI<PopupConfigResponse>({
        method: HTTP_METHOD.GET,
        path:   'popup/config'
    });

    return body;
};
