import { assertUnreachable, localStorageGet, localStorageRemove, localStorageSet } from './util';

const POPUP_COOLDOWN_DAYS = 7;
const TEASER_COOLDOWN_DAYS = 1;
const MILLISECONDS_PER_DAY = 24 * 60 * 60 * 1000;

export enum MODAL_TYPE {
    POPUP = 'popup',
    TEASER = 'teaser'
}

type CooldownParams = {
    id : string,
    type : MODAL_TYPE,
};

const constructKey = ({ id, type } : CooldownParams) : string => {
    const prefix = `ot_${ type }`;
    const parts = [ prefix, id, 'last_closed_at' ];
    return parts.filter(Boolean).join('_');
};

export const setLastClosedTime = (params : CooldownParams) : void => {
    const key = constructKey(params);
    localStorageSet(key, Date.now().toString());
};

const getCooldownDays = (type : MODAL_TYPE) : number => {
    switch (type) {
        case MODAL_TYPE.POPUP:
            return POPUP_COOLDOWN_DAYS;
        case MODAL_TYPE.TEASER:
            return TEASER_COOLDOWN_DAYS;
        default:
            throw assertUnreachable(type);
    }
};

export const isCoolingDown = ({ id, type } : CooldownParams) : boolean => {
    const key = constructKey({ id, type });
    const lastClosedAt = localStorageGet(key);

    if (!lastClosedAt) {
        return false;
    }

    const cooldownDays = getCooldownDays(type);
    const cooldownPeriod = cooldownDays * MILLISECONDS_PER_DAY;
    return (Date.now() - Number.parseInt(lastClosedAt, 10)) < cooldownPeriod;
};

export const removeLastClosedTime = (params : CooldownParams) : void => {
    const key = constructKey(params);
    localStorageRemove(key);
};
