// Set up preact context

import { createContext } from 'preact';
import { useContext } from 'preact/hooks';

import type { ShouldSuppressResult } from '../../../lib';
import type { BrandConfig, SetState } from '../../../types';

import type { Email, PhoneNumber, PopupConfig, TOKEN, TokenType } from '@onetext/api';

export type ConsentPopupContextType = {
    accountToken : TokenType<TOKEN.ACCOUNT> | undefined,
    properties : Record<string, string>,
    setProperties : SetState<Record<string, string>>,
    email : Email | undefined,
    setEmail : SetState<Email | undefined>,
    phone : PhoneNumber | undefined,
    setPhone : SetState<PhoneNumber | undefined>,
    registerPage : (pageID : number) => () => void,
    getNewPageID : () => number,
    activePageID : number | undefined,
    submitPage : () => Promise<void>,
    isPopupOpen : boolean,
    setIsPopupOpen : SetState<boolean>,
    isTeaserOpen : boolean,
    setIsTeaserOpen : SetState<boolean>,
    openPopup : () => void,
    closePopup : () => void,
    closeTeaser : () => void,
    suppressedRoutes : ReadonlyArray<string> | undefined,
    popupID : string,
    brandConfig : BrandConfig | undefined,
    config : PopupConfig,
    suppressTeaser : ShouldSuppressResult,
    suppressPopup : ShouldSuppressResult,
};

export const ConsentPopupContext = createContext<ConsentPopupContextType | undefined>(undefined);

export const useConsentPopupContext = () : ConsentPopupContextType => {
    const context = useContext(ConsentPopupContext);

    if (!context) {
        throw new Error('Can not find consent popup context');
    }

    return context;
};
